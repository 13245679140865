import { BalanceAdjustmentDetails, IBalanceAdjustmentDetails } from '../BalanceAdjustmentDetails';
import { Box, Tooltip } from '@mui/material';
import { DataGridPremium, DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { useCallback, useMemo } from 'react';

import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import clsx from 'clsx';
import { formatBalance } from '../../utils';
import useStyles from './styles';

interface IProps {
  balanceSheetTotal: number;
  accountsTotal: number;
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
  project: IProject;
  types: number[];
  balancingType: number;
  isBalanced: boolean;
  updateAdjustmentDefinition?: (
    url: string,
    data: IUpdateAdjustmentDefinitionData,
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition?: (url: string) => void;
}

export const BalanceAdjustmentsTable = ({
  balanceSheetTotal,
  accountsTotal,
  adjustments,
  accounts,
  category,
  project,
  balancingType,
  types,
  isBalanced,
  updateAdjustmentDefinition,
  deleteAdjustmentDefinition,
}: IProps) => {
  const { classes } = useStyles();

  const balancingAccount = useMemo(
    () => accounts.find((x) => x.accountType.type === balancingType),
    [accounts, balancingType],
  );

  const columns = useMemo(
    () =>
      [
        {
          field: 'balancingAccountName',
          headerName: 'Section Adjustments',
          type: 'string',
          flex: 4,
          renderCell: (params) => {
            if (params.rowNode.type === 'pinnedRow') return '';
            return params.row.adjustmentDefinitionId ? (
              params.value
            ) : (
              <Tooltip
                title={`Since a Balance Sheet target total has been established, a section adjustment should be created to ensure the account totals 
                  balance with the Balance Sheet total. To achieve this, assign one or more accounts as targets for the section adjustment.`}
              >
                <Box className={clsx([classes.flex, classes.red])}>
                  <img src={AlertIcon} alt='alert' />
                  {params.value}
                </Box>
              </Tooltip>
            );
          },
        },
        {
          field: 'balanceSheetTotal',
          headerName: 'Balance Sheet Total',
          type: 'number',
          flex: 2,
          renderCell: (params) => (
            <Box className={clsx([!params.row.adjustmentDefinitionId && classes.red])}>
              {params.value !== undefined ? formatBalance(params.value || 0) : ''}
            </Box>
          ),
        },
        {
          field: 'accountsTotal',
          headerName: 'Accounts Total',
          type: 'number',
          flex: 2,
          renderCell: (params) => (
            <Box className={clsx([!params.row.adjustmentDefinitionId && classes.red])}>
              {params.value !== undefined ? formatBalance(params.value || 0) : ''}
            </Box>
          ),
        },
        {
          field: 'adjustment',
          headerName: 'Total Adjustment',
          type: 'number',
          flex: 2,
          renderCell: (params) => (
            <Box className={clsx([!params.row.adjustmentDefinitionId && classes.red])}>
              {params.value !== undefined ? formatBalance(params.value || 0) : ''}
            </Box>
          ),
        },
        {
          field: 'adjustmentToSection',
          headerName: 'Adjustment to Section',
          type: 'number',
          flex: 2,
          renderCell: (params) => (
            <Box
              className={clsx([
                params.rowNode.type !== 'pinnedRow' &&
                  !params.row.adjustmentDefinitionId &&
                  classes.red,
              ])}
            >
              {formatBalance(params.value || 0)}
            </Box>
          ),
        },
      ] as GridColDef<IBalanceAdjustmentDetails>[],
    [],
  );

  const adjustmentDetails = useMemo<IBalanceAdjustmentDetails[]>(() => {
    const grouped = new Map<string, IBalanceAdjustmentDetails>();

    adjustments.forEach((adjustment) => {
      const {
        adjustmentDefinitionId,
        sourceAccountId,
        sourceAdjustment,
        sourceTotal,
        amount,
        accountId,
      } = adjustment;
      const sourceAccount = accounts.find((x) => x.id === sourceAccountId);
      const targetAccount = accounts.find((x) => x.id === accountId);
      if (!grouped.has(adjustmentDefinitionId)) {
        grouped.set(adjustmentDefinitionId, {
          balanceSheetTotal: sourceTotal,
          accountsTotal: sourceTotal - sourceAdjustment,
          adjustment: sourceAdjustment,
          adjustmentToSection: 0,
          balancingAccountName: sourceAccount?.accountName || 'Unknown',
          balancingAccountId: sourceAccount?.id || '',
          adjustments: [],
          adjustmentDefinitionId,
        });
      }

      const group = grouped.get(adjustmentDefinitionId);

      if (group) {
        group.adjustments.push(adjustment);
        if (targetAccount && types.includes(targetAccount.accountType.type))
          group.adjustmentToSection += amount;
      }
    });

    const balanceAdjustmentDetails = Array.from(grouped.values());

    if (
      !isBalanced &&
      !!balancingAccount &&
      !balanceAdjustmentDetails.some((x) => x.balancingAccountId === balancingAccount.id)
    ) {
      return [
        {
          balanceSheetTotal,
          accountsTotal,
          adjustment: balanceSheetTotal - accountsTotal,
          adjustmentToSection: 0,
          balancingAccountName: balancingAccount.accountName,
          balancingAccountId: balancingAccount.id,
          adjustments: [],
        },
        ...balanceAdjustmentDetails,
      ];
    }

    return balanceAdjustmentDetails;
  }, [
    balanceSheetTotal,
    accountsTotal,
    adjustments,
    accounts,
    types,
    isBalanced,
    balancingAccount,
  ]);

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(
    ({ row }) => (
      <BalanceAdjustmentDetails
        details={row}
        accounts={accounts}
        adjustments={adjustments}
        category={category}
        project={project}
        types={types}
        updateAdjustmentDefinition={updateAdjustmentDefinition}
        deleteAdjustmentDefinition={deleteAdjustmentDefinition}
      />
    ),
    [accounts, adjustments, category, project],
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={adjustmentDetails}
          hideFooter
          density='compact'
          columns={columns}
          className={classes.table}
          disableColumnMenu
          getRowId={(row) => row.adjustmentDefinitionId || ''}
          initialState={{
            sorting: {
              sortModel: [{ field: 'balancingAccountName', sort: 'asc' }],
            },
            aggregation: {
              model: {
                adjustmentToSection: 'sum',
              },
            },
          }}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
        />
      </Box>
    </>
  );
};
