import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { formatBalance, useTableExpand } from '../../../../utils';
import { useApi, useLoader, useUpdateEffect } from '@hooks';
import { useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { Box } from '@mui/material';
import { IProject } from '@models/interfaces/entities/IProject';
import { ITypeCode } from '@models/interfaces/entities/ITypeCode';
import { Loader } from '@components/Loader';
import { StandardTableFooter } from '../../../StandardTableFooter';
import clsx from 'clsx';
import { getTypeCodes } from '@services/api';
import useStyles from './styles';

interface IProps {
  project: IProject;
  type: number;
}

export const IgnoredTypeCodesTab = ({ project, type }: IProps) => {
  const { classes } = useStyles();

  const [typeCodes, setTypeCodes] = useState<ITypeCode[]>([]);

  const { isTableExpanded, onToggleTableExpand } = useTableExpand();

  const {
    request: getTypeCodesRequest,
    data: getTypeCodesData,
    loading: getTypeCodesLoading,
  } = useApi(getTypeCodes, null, { handleErrors: true });

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'code',
        headerName: 'Type Code',
        type: 'string',
        flex: 2,
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        flex: 2,
      },
      {
        field: 'reason',
        headerName: 'Reason',
        type: 'string',
        flex: 2,
      },
      {
        field: 'balance',
        headerName: 'Balance',
        type: 'number',
        flex: 2,
        renderCell: (params) => formatBalance(params.value || 0),
      },
    ];
  }, []);

  useEffect(() => {
    if (project.links[Actions.getTypeCodes]) {
      getTypeCodesRequest(
        project.links[Actions.getTypeCodes].href,
        undefined,
        type,
        false,
        true,
        false,
      );
    }
  }, [project.links[Actions.getTypeCodes], type]);

  const dataItems = useMemo(
    () =>
      typeCodes.map((x) => ({
        code: x.typeCode,
        description: x.description,
        reason: x.ignoreReason || 'Unknown',
        balance: x.balance,
      })),
    [typeCodes],
  );

  useUpdateEffect(() => {
    if (getTypeCodesData) {
      setTypeCodes(getTypeCodesData.items);
    }
  }, [getTypeCodesData]);

  const showLoader = useLoader(getTypeCodesLoading);

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          rows={dataItems}
          density='compact'
          columns={columns}
          className={clsx([classes.table, !isTableExpanded && classes.limitedHeightTable])}
          initialState={{
            sorting: {
              sortModel: [{ field: 'code', sort: 'asc' }],
            },
            aggregation: {
              model: {
                balance: 'sum',
              },
            },
          }}
          getRowId={(row) => row.code}
          slots={{
            footer: () => (
              <StandardTableFooter
                showTableExpandSwitch={dataItems.length > 10}
                isTableExpanded={isTableExpanded}
                onToggleTableExpand={onToggleTableExpand}
              />
            ),
          }}
        />
      </Box>
      <Loader show={showLoader} fixed={false} />
    </>
  );
};
