import { AdjustmentOperation, OperationMap } from '../../common/AdjustmentOperation';
import { Box, Container, IconButton, Switch, Tooltip } from '@mui/material';
import { IAdjustmentDetails, IAdjustmentDetailsGroupSource } from '../AdjustmentDetails';
import { formatBalance, formatPercentage } from '../../utils';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { getAdjustmentDefinition, getAdjustmentDefinitions, getAdjustments } from '@services/api';
import { useApi, useConfirm, useLoader } from '@hooks';

import { AccountsDialog } from '../AccountsDialog';
import { Actions } from '@models/enums/Actions';
import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import CheckIcon from '@assets/icons/dashboard/check-circle-filled.svg';
import EditIcon from '@assets/icons/dashboard/edit.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { ICreateAdjustmentDefinitionData } from '@models/interfaces/additional/ICreateAdjustmentDefinitionData';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { Loader } from '@components/Loader';
import { Methods } from '@models/enums/Methods';
import MinusIcon from '@assets/icons/dashboard/minus.svg';
import PlusIcon from '@assets/icons/dashboard/plus-square-green.svg';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import XIcon from '@assets/icons/dashboard/x-circle-red.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  detailsArray: IAdjustmentDetails[];
  accounts: IAccount[];
  category: string;
  project: IProject;
  types: number[];
  createAdjustmentDefinition?: (
    url: string,
    data: ICreateAdjustmentDefinitionData[],
    callback?: () => void,
  ) => void;
  updateAdjustmentDefinition?: (
    url: string,
    data: IUpdateAdjustmentDefinitionData,
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition?: (url: string) => void;
}

export const MatchAdjustmentDetails = forwardRef(function MatchAdjustmentDetails(
  {
    detailsArray,
    accounts,
    category,
    project,
    types,
    createAdjustmentDefinition,
    updateAdjustmentDefinition,
    deleteAdjustmentDefinition,
  }: IProps,
  ref: React.Ref<unknown>,
) {
  const { classes } = useStyles();
  const confirm = useConfirm();

  const [currentDetailsArray, setCurrentDetailsArray] = useState<IAdjustmentDetails[]>([
    ...detailsArray,
  ]);

  const [details] = useState(currentDetailsArray[0]);

  const [adjustmentDefinition, setAdjustmentDefinition] = useState<IAdjustmentDefinition>();
  const [detailsAdjustments, setDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [detailsGroupSources, setDetailsGroupSources] = useState<IAdjustmentDetailsGroupSource[]>(
    details.groupSources.slice(),
  );
  const [balanceAdjustments, setBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [allMatchAdjustmentDefinitions, setAllMatchAdjustmentDefinitions] = useState<
    IAdjustmentDefinition[]
  >([]);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [baselineAccountIds, setBaselineAccountIds] = useState<string[]>([]);
  const [targetAccountIds, setTargetAccountIds] = useState<string[]>([]);
  const [reverseBalanceConfiguration, setReverseBalanceConfiguration] = useState(
    currentDetailsArray.map((x) => ({
      sourceSubAccountId: x.sourceSubAccountId,
      reverseBalance: x.reverseBalance,
    })),
  );
  const [storedDetailsAdjustments, setStoredDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [storedDetailsGroupSources, setStoredDetailsGroupSources] = useState<
    IAdjustmentDetailsGroupSource[]
  >([]);
  const [storedBalanceAdjustments, setStoredBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [openAccountsDialog, setOpenAccountsDialog] = useState(false);

  const balanceOperations = useMemo(
    () => [AdjustmentOperation.Initial, AdjustmentOperation.Manual],
    [],
  );

  const groupBaselineAccountsIds = useMemo(() => {
    const groupMap = new Map<string, Set<string>>();

    allMatchAdjustmentDefinitions.forEach((definition) => {
      const groupId = definition.groupId || '';
      if (!groupMap.has(groupId)) {
        groupMap.set(groupId, new Set<string>());
      }

      definition.baseline.forEach((baseline) => {
        groupMap.get(groupId)?.add(baseline.id);
      });
    });

    return Array.from(groupMap.entries()).map(([groupId, accountIdSet]) => ({
      groupId,
      accountIds: Array.from(accountIdSet),
    }));
  }, [allMatchAdjustmentDefinitions]);

  const sourceTotalSum = useMemo(
    () =>
      detailsGroupSources.reduce(
        (sum, source) =>
          sum +
          source.sourceTotal *
            ((
              reverseBalanceConfiguration.find(
                (x) => x.sourceSubAccountId === source.sourceSubAccountId,
              ) || source
            ).reverseBalance
              ? -1
              : 1),
        0,
      ),
    [detailsGroupSources, reverseBalanceConfiguration],
  );

  const getAccountTotalBalance = useCallback(
    (accountId: string, operations: string[], adjustments: IAdjustment[]) => {
      return adjustments
        .filter((x) => x.accountId === accountId && operations.includes(x.operation))
        .reduce((p, c) => p + c.amount, 0);
    },
    [],
  );

  const baselineAccountBalances = useMemo(() => {
    return baselineAccountIds.map((x) => ({
      accountId: x,
      initialBalance: getAccountTotalBalance(x, [AdjustmentOperation.Initial], balanceAdjustments),
      balance: getAccountTotalBalance(x, balanceOperations, balanceAdjustments),
    }));
  }, [baselineAccountIds, balanceAdjustments, balanceOperations]);

  const totalTargetAccountsBalance = useMemo(
    () =>
      baselineAccountBalances
        .filter((x) => targetAccountIds.includes(x.accountId))
        .reduce((prev, acc) => prev + acc.balance, 0),
    [baselineAccountBalances, targetAccountIds],
  );

  const totalBaselineAccountsInitialBalance = useMemo(
    () => baselineAccountBalances.reduce((prev, acc) => prev + acc.initialBalance, 0),
    [baselineAccountBalances, baselineAccountIds],
  );

  const totalSourceAdjustment = useMemo(
    () => sourceTotalSum - totalBaselineAccountsInitialBalance,
    [sourceTotalSum, totalBaselineAccountsInitialBalance],
  );

  const currentSourceAdjustment = useMemo(
    () =>
      (sourceTotalSum
        ? currentDetailsArray.reduce(
            (sum, item) =>
              sum +
              ((
                reverseBalanceConfiguration.find(
                  (x) => x.sourceSubAccountId === item.sourceSubAccountId,
                ) || item
              ).reverseBalance
                ? -1
                : 1) *
                item.sourceTotal,
            0,
          ) / sourceTotalSum
        : currentDetailsArray.length / detailsGroupSources.length) * totalSourceAdjustment,
    [
      currentDetailsArray,
      reverseBalanceConfiguration,
      sourceTotalSum,
      totalSourceAdjustment,
      detailsGroupSources.length,
    ],
  );

  const currentSourcePortion = useCallback(
    (source: IAdjustmentDetailsGroupSource) => {
      const detailsItem = currentDetailsArray.find(
        (x) => x.sourceSubAccountId === source.sourceSubAccountId,
      );
      if (!detailsItem) return 0;
      return sourceTotalSum
        ? (((
            reverseBalanceConfiguration.find(
              (x) => x.sourceSubAccountId === detailsItem.sourceSubAccountId,
            ) || detailsItem
          ).reverseBalance
            ? -1
            : 1) *
            detailsItem.sourceTotal) /
            sourceTotalSum
        : 1 / detailsGroupSources.length;
    },
    [currentDetailsArray, reverseBalanceConfiguration, sourceTotalSum, detailsGroupSources.length],
  );

  const currentSourcesPortion = useMemo(
    () => currentDetailsArray.reduce((sum, item) => sum + currentSourcePortion(item), 0),
    [currentSourcePortion, currentDetailsArray],
  );

  const { request: getAdjustmentDefinitionRequest, loading: getAdjustmentDefinitionLoading } =
    useApi(getAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAdjustmentDefinition(data);
          setTargetAccountIds(data.targets.map((x) => x.id));
          setBaselineAccountIds(data.baseline.map((x) => x.id));
        }
      },
    });

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceAdjustments = data.items.filter((x) =>
            balanceOperations.includes(x.operation as AdjustmentOperation),
          );
          setBalanceAdjustments(balanceAdjustments);

          const detailsAdjustments = data.items.filter(
            (x) => x.adjustmentDefinitionId === details.adjustmentDefinitionId,
          );
          setDetailsAdjustments(detailsAdjustments);
        }
      },
    },
  );

  const { request: reloadBalanceAdjustmentsRequest, loading: reloadBalanceAdjustmentsLoading } =
    useApi(
      async (link: ILink, accountIds: string[]) => {
        const data = await getAdjustments(link.href, balanceOperations, accountIds);
        return data;
      },
      null,
      {
        handleErrors: true,
        onCallback: (data) => {
          if (data) {
            const balanceAdjustments = data.items.filter((x) =>
              balanceOperations.includes(x.operation as AdjustmentOperation),
            );
            setBalanceAdjustments(balanceAdjustments);
          }
        },
      },
    );

  const { request: reloadMatchAdjustmentsRequest, loading: reloadMatchAdjustmentsLoading } = useApi(
    async (link: ILink, accountIds: string[]) => {
      const data = await getAdjustments(link.href, [AdjustmentOperation.Match], accountIds);
      return data;
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const calculatedGroupSources: {
            sourceSubAccountId?: string | null;
            sourceTotal: number;
            reverseBalance: boolean;
          }[] = details.groupSources.slice();
          const processedSourceSubAccountIds = new Set<string>(
            details.groupSources.map((x) => x.sourceSubAccountId || ''),
          );

          data.items.forEach(({ sourceSubAccountId, sourceTotal, reverseBalance }) => {
            if (sourceSubAccountId && !processedSourceSubAccountIds.has(sourceSubAccountId)) {
              processedSourceSubAccountIds.add(sourceSubAccountId);
              calculatedGroupSources.push({
                sourceSubAccountId,
                sourceTotal,
                reverseBalance,
              });
            }
          });

          setDetailsGroupSources(calculatedGroupSources);
        }
      },
    },
  );

  const {
    request: getAllMatchAdjustmentDefinitionsRequest,
    loading: getAllMatchAdjustmentDefinitionsLoading,
  } = useApi(
    async (link: ILink) => {
      const data = await getAdjustmentDefinitions(link.href, AdjustmentOperation.Match);
      return data;
    },
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAllMatchAdjustmentDefinitions(data.items);
        }
      },
    },
  );

  const detailAdjustmentItems = useMemo(() => {
    const baselineOnlyAccountIds = baselineAccountIds.filter(
      (id) => !targetAccountIds.includes(id),
    );

    const targetAccountsData = detailsAdjustments.map((x) => {
      const account = accounts.find((a) => a.id === x.accountId);
      const targetAccountBalance = baselineAccountBalances.find(
        (tab) => tab.accountId === x.accountId,
      );
      return {
        account,
        initialBalance: targetAccountBalance?.initialBalance || 0,
        balance: targetAccountBalance?.balance || 0,
        adjustment: !editModeEnabled
          ? x.amount
          : (totalTargetAccountsBalance !== 0
              ? (targetAccountBalance?.balance || 0) / totalTargetAccountsBalance
              : 1 / targetAccountIds.length) * currentSourceAdjustment,
        adjusted: true,
        isBaseline: baselineAccountIds.includes(x.accountId),
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    const baselineAccountsData = baselineOnlyAccountIds.map((x) => {
      const account = accounts.find((a) => a.id === x);
      return {
        account,
        initialBalance: getAccountTotalBalance(
          x,
          [AdjustmentOperation.Initial],
          balanceAdjustments,
        ),
        balance: 0,
        adjustment: 0,
        adjusted: false,
        isBaseline: true,
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    return [...targetAccountsData, ...baselineAccountsData].sort((a, b) =>
      (a.account?.accountName || 'Unknown').localeCompare(b.account?.accountName || 'Unknown'),
    );
  }, [
    detailsAdjustments,
    accounts,
    types,
    balanceAdjustments,
    details.operation,
    details.adjustmentDefinitionId,
    currentSourceAdjustment,
    adjustmentDefinition,
    baselineAccountBalances,
    totalTargetAccountsBalance,
    targetAccountIds,
    baselineAccountIds,
    editModeEnabled,
  ]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter(
        (x) => types.includes(x.accountType.type) && x.summaryCode !== 1 && !x.isBalancing,
      ),
    [accounts, types],
  );

  const onChangeReverseBalance = (subAccountId: string | undefined, value: boolean) => {
    setReverseBalanceConfiguration((prev) =>
      prev.map((x) =>
        x.sourceSubAccountId === subAccountId ? { ...x, reverseBalance: value } : x,
      ),
    );
  };

  const onSave = (sources?: { type: string; id: string; typeCode: string }[]) => {
    if (!currentDetailsArray.length) {
      toast.error('At least one line item should be specified');
      return false;
    }
    if (!targetAccountIds.length) {
      toast.error('At least one target account should be specified');
      return false;
    }

    if (details.adjustmentDefinitionId) {
      const [firstAdjustment] = detailsAdjustments;
      if (
        updateAdjustmentDefinition !== undefined &&
        !!firstAdjustment?.links[Actions.updateDefinition] &&
        adjustmentDefinition
      ) {
        const reverseBalance = reverseBalanceConfiguration.find(
          (x) => x.sourceSubAccountId === details.sourceSubAccountId,
        )?.reverseBalance;
        updateAdjustmentDefinition(
          firstAdjustment.links[Actions.updateDefinition].href,
          {
            reverseBalance:
              reverseBalance !== undefined && details.reverseBalance !== reverseBalance
                ? reverseBalance
                : undefined,
            source: {
              type: adjustmentDefinition.source.type,
              id: adjustmentDefinition.source.value.id,
            },
            targets: targetAccountIds,
            operation: adjustmentDefinition.operation,
            baseline: baselineAccountIds,
            tierLimits: [],
          },
          () => {
            if (project.links[Actions.getAdjustmentDefinitions]?.href)
              getAdjustmentDefinitionRequest(
                `${project.links[Actions.getAdjustmentDefinitions].href}/${
                  details.adjustmentDefinitionId
                }`,
              );
            if (project.links[Actions.getAdjustmentDefinitions]?.href)
              getAllMatchAdjustmentDefinitionsRequest(
                project.links[Actions.getAdjustmentDefinitions],
              );
            setEditModeEnabled(false);
          },
        );
      }
    } else {
      if (createAdjustmentDefinition !== undefined && sources?.length) {
        createAdjustmentDefinition(
          project.links[Actions.createAdjustmentDefinition].href,
          sources
            .filter((x) => detailsGroupSources.some((dgs) => dgs.sourceSubAccountId === x.typeCode))
            .map((x) => ({
              description: '',
              reverseBalance:
                reverseBalanceConfiguration.find((rbc) => rbc.sourceSubAccountId === x.typeCode)
                  ?.reverseBalance || false,
              source: {
                type: x.type,
                id: x.id,
              },
              targets: targetAccountIds,
              operation: details.operation,
              baseline: baselineAccountIds,
              tierLimits: [],
            })),
        );
      }
    }
    return true;
  };

  const onDiscard = () => {
    setTargetAccountIds(adjustmentDefinition?.targets.map((x) => x.id) || []);
    setBaselineAccountIds(adjustmentDefinition?.baseline.map((x) => x.id) || []);
    setCurrentDetailsArray([...detailsArray]);
    setReverseBalanceConfiguration(
      detailsArray.map((x) => ({
        sourceSubAccountId: x.sourceSubAccountId,
        reverseBalance: x.reverseBalance,
      })),
    );
    setDetailsAdjustments(storedDetailsAdjustments.slice());
    setDetailsGroupSources(storedDetailsGroupSources.slice());
    setBalanceAdjustments(storedBalanceAdjustments.slice());
    setEditModeEnabled(false);
  };

  const onEdit = () => {
    setTargetAccountIds(adjustmentDefinition?.targets.map((x) => x.id) || []);
    setBaselineAccountIds(adjustmentDefinition?.baseline.map((x) => x.id) || []);
    setCurrentDetailsArray([...detailsArray]);
    setReverseBalanceConfiguration(
      detailsArray.map((x) => ({
        sourceSubAccountId: x.sourceSubAccountId,
        reverseBalance: x.reverseBalance,
      })),
    );
    setStoredDetailsAdjustments(detailsAdjustments.slice());
    setStoredDetailsGroupSources(detailsGroupSources.slice());
    setStoredBalanceAdjustments(balanceAdjustments.slice());
    setEditModeEnabled(true);
  };

  const onDelete = async () => {
    const [firstAdjustment] = detailsAdjustments;
    if (
      deleteAdjustmentDefinition !== undefined &&
      !!firstAdjustment?.links[Actions.deleteDefinition]
    ) {
      await confirm({
        title: 'Delete adjustment',
        description: 'Are you sure you wish to delete this adjustment?',
        confirmationText: 'Confirm',
      });

      deleteAdjustmentDefinition(firstAdjustment.links[Actions.deleteDefinition].href);
    }
  };

  const subAccountsToBaselineAccountsMap = useMemo(() => {
    return allMatchAdjustmentDefinitions.map((x) => ({
      adjustmentDefinitionId: x.id,
      sourceSubAccountId: x.source.value?.title,
      accountsIds: x.baseline.map((a) => a.id),
    }));
  }, [allMatchAdjustmentDefinitions]);

  const onRemoveAccount = (accountId: string) => {
    setTargetAccountIds((prev) => prev.filter((x) => x !== accountId));
    setBaselineAccountIds((prevBaseline) => {
      const updatedBaseline = prevBaseline.filter((x) => x !== accountId);

      setDetailsGroupSources((prevSources) =>
        prevSources.filter((source) => {
          const sourceBaselineAccounts =
            subAccountsToBaselineAccountsMap.find(
              (m) => m.sourceSubAccountId === source.sourceSubAccountId,
            )?.accountsIds || [];
          return (
            details.groupSources.some((x) => x.sourceSubAccountId === source.sourceSubAccountId) ||
            updatedBaseline.some((id) => sourceBaselineAccounts.includes(id))
          );
        }),
      );

      return updatedBaseline;
    });
    setDetailsAdjustments((prev) => prev.filter((x) => x.accountId !== accountId));
  };

  const onRemoveLineItem = (subAccountId: string) => {
    setCurrentDetailsArray((prev) =>
      prev
        .filter((x) => x.sourceSubAccountId !== subAccountId)
        .map((x) => ({
          ...x,
          groupSources: x.groupSources.filter((sg) => sg.sourceSubAccountId !== subAccountId),
        })),
    );

    setDetailsGroupSources((prev) => prev.filter((x) => x.sourceSubAccountId !== subAccountId));
    setReverseBalanceConfiguration((prev) =>
      prev.filter((x) => x.sourceSubAccountId !== subAccountId),
    );
  };

  const onAddAccount = () => {
    setOpenAccountsDialog(true);
  };

  const onCloseAccountsDialog = (selectedAccountsIds: string[]) => {
    const allSelectedAccountIds = new Set<string>();

    selectedAccountsIds.forEach((selectedId) => {
      let isInGroup = false;
      groupBaselineAccountsIds.forEach((group) => {
        if (group.accountIds.includes(selectedId)) {
          isInGroup = true;
          group.accountIds.forEach((id) => allSelectedAccountIds.add(id));
        }
      });
      if (!isInGroup) {
        allSelectedAccountIds.add(selectedId);
      }
    });

    const allSelectedAccountIdsArray = Array.from(allSelectedAccountIds);

    if (project.links[Actions.getAdjustments]) {
      reloadBalanceAdjustmentsRequest(
        project.links[Actions.getAdjustments],
        allSelectedAccountIdsArray,
      );
    }

    if (allSelectedAccountIdsArray.length) {
      if (project.links[Actions.getAdjustments]) {
        reloadMatchAdjustmentsRequest(
          project.links[Actions.getAdjustments],
          allSelectedAccountIdsArray,
        );
      }
    } else {
      setDetailsGroupSources(details.groupSources);
    }
    setBaselineAccountIds(allSelectedAccountIdsArray);
    setDetailsAdjustments((prev) =>
      prev.filter((x) => allSelectedAccountIdsArray.includes(x.accountId)),
    );
    setTargetAccountIds((prev) => prev.filter((x) => allSelectedAccountIdsArray.includes(x)));

    setOpenAccountsDialog(false);
  };

  const onChangeAdjustStatus = (value: boolean, accountId: string) => {
    if (value) {
      setTargetAccountIds((prev) => [...prev, accountId]);
    } else {
      setTargetAccountIds((prev) => prev.filter((x) => x !== accountId));
    }
  };

  useImperativeHandle(ref, () => ({
    save: onSave,
  }));

  useEffect(() => {
    setDetailsAdjustments((prev) => {
      const filteredAdjustments = prev.filter((x) => targetAccountIds.includes(x.accountId));
      const accountsIdsWithoutAdjustment = targetAccountIds.filter(
        (x) => prev.findIndex((a) => a.accountId === x) === -1,
      );
      return [
        ...filteredAdjustments,
        ...accountsIdsWithoutAdjustment.map(
          (x) =>
            ({
              accountId: x,
              operation: details.operation,
              id: uuidv4(),
              adjustmentDefinitionId: details.adjustmentDefinitionId,
              reverseBalance: details.reverseBalance,
              amount: 0,
              sourceAdjustment: currentSourceAdjustment,
              sourceTotal: details.sourceTotal,
              links: {
                [Actions.updateDefinition]: {
                  href: `${project.links[Actions.getAdjustmentDefinitions].href}/${
                    details.adjustmentDefinitionId
                  }`,
                  method: Methods.put,
                } as ILink,
              },
            } as IAdjustment),
        ),
      ];
    });
  }, [targetAccountIds]);

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]?.href && details.adjustmentDefinitionId)
      getAdjustmentDefinitionRequest(
        `${project.links[Actions.getAdjustmentDefinitions].href}/${details.adjustmentDefinitionId}`,
      );
  }, [project.links[Actions.getAdjustmentDefinitions]?.href, details.adjustmentDefinitionId]);

  useEffect(() => {
    if (adjustmentDefinition) {
      const usedAccountIds = Array.from(
        new Set([
          ...adjustmentDefinition.targets.map((x) => x.id),
          ...adjustmentDefinition.baseline.map((x) => x.id),
        ]),
      );

      if (project.links[Actions.getAdjustments]) {
        getAdjustmentsRequest(
          project.links[Actions.getAdjustments].href,
          [...balanceOperations, details.operation],
          usedAccountIds,
        );
      }
    }
  }, [
    filteredAccounts,
    adjustmentDefinition,
    balanceOperations,
    project.links[Actions.getAdjustments]?.href,
  ]);

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]) {
      getAllMatchAdjustmentDefinitionsRequest(project.links[Actions.getAdjustmentDefinitions]);
    }
  }, [project.links[Actions.getAdjustmentDefinitions]?.href]);

  const detailAdjustmentsTotalAdjustment = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.adjustment, 0),
    [detailAdjustmentItems],
  );

  const showLoader = useLoader(
    getAdjustmentDefinitionLoading,
    getAdjustmentsLoading,
    reloadBalanceAdjustmentsLoading,
    getAllMatchAdjustmentDefinitionsLoading,
    reloadMatchAdjustmentsLoading,
  );

  const deleteEnabled = useMemo(() => {
    const [firstAdjustment] = detailsAdjustments;
    return (
      deleteAdjustmentDefinition !== undefined && !!firstAdjustment?.links[Actions.deleteDefinition]
    );
  }, [deleteAdjustmentDefinition, detailsAdjustments]);

  const updateEnabled = useMemo(() => {
    const [firstAdjustment] = detailsAdjustments;
    return (
      updateAdjustmentDefinition !== undefined && !!firstAdjustment?.links[Actions.updateDefinition]
    );
  }, [updateAdjustmentDefinition, detailsAdjustments, details.operation]);

  const createEnabled = useMemo(() => {
    return (
      createAdjustmentDefinition !== undefined &&
      !!project.links[Actions.createAdjustmentDefinition] &&
      !details.adjustmentDefinitionId
    );
  }, [updateAdjustmentDefinition, details.adjustmentDefinitionId, details.operation]);

  useEffect(() => {
    if (createEnabled) {
      setEditModeEnabled(true);
    }
  }, [createEnabled]);

  const isEditableSource = useCallback(
    (source: IAdjustmentDetailsGroupSource) => {
      return currentDetailsArray.some((x) => x.sourceSubAccountId === source.sourceSubAccountId);
    },
    [currentDetailsArray],
  );

  const computedReverseBalance = useCallback(
    (source: IAdjustmentDetailsGroupSource) => {
      return !isEditableSource(source)
        ? source.reverseBalance
        : !editModeEnabled
        ? currentDetailsArray.find((x) => x.sourceSubAccountId === source.sourceSubAccountId)
            ?.reverseBalance || false
        : reverseBalanceConfiguration.find(
            (x) => x.sourceSubAccountId === source.sourceSubAccountId,
          )?.reverseBalance || false;
    },
    [isEditableSource, currentDetailsArray, reverseBalanceConfiguration],
  );

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          <Box className={classes.grid}>
            <Box className={clsx([classes.header, classes.colSpan5, classes.mh26])}>
              {!createEnabled && <Box>{details.sourceSubAccountId}</Box>}
              {!editModeEnabled && updateEnabled && (
                <Tooltip title='Edit adjustment'>
                  <IconButton onClick={onEdit} size='small'>
                    <img src={EditIcon} alt='edit' />
                  </IconButton>
                </Tooltip>
              )}
              {!editModeEnabled && deleteEnabled && (
                <Tooltip title='Delete adjustment'>
                  <IconButton onClick={onDelete} size='small'>
                    <img src={TrashIcon} alt='trash' />
                  </IconButton>
                </Tooltip>
              )}
              {editModeEnabled && !createEnabled && (
                <Tooltip title='Accept changes'>
                  <IconButton
                    onClick={() => {
                      onSave();
                    }}
                    size='small'
                  >
                    <img src={CheckIcon} alt='check' />
                  </IconButton>
                </Tooltip>
              )}
              {editModeEnabled && !createEnabled && (
                <Tooltip title='Discard changes'>
                  <IconButton onClick={onDiscard} size='small'>
                    <img src={XIcon} alt='x' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {OperationMap[details.operation]}
            </Box>
            {details.sourceSubAccountDescription && !createEnabled && (
              <Box className={clsx([classes.colSpan6, classes.mh26])}>
                {details.sourceSubAccountDescription}
              </Box>
            )}
            <Box className={classes.spacer} />

            <Box className={clsx([classes.header, 'alignItemsEnd'])}>Balance Sheet Line Items</Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Balance
            </Box>
            <Box className={clsx([classes.header, classes.textCenter, 'alignItemsEnd'])}>
              Reverse Balance
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Portion of Balance
            </Box>

            {detailsGroupSources.map((source) => (
              <>
                <Box
                  className={clsx([
                    (!createEnabled || (createEnabled && !isEditableSource(source))) &&
                      classes.pl30,
                    classes.col1,
                    'gap4',
                    classes.mh26,
                  ])}
                  key={source.sourceSubAccountId || ''}
                >
                  {editModeEnabled && createEnabled && isEditableSource(source) && (
                    <Tooltip title='Remove line item'>
                      <IconButton
                        onClick={() =>
                          source.sourceSubAccountId && onRemoveLineItem(source.sourceSubAccountId)
                        }
                        size='small'
                      >
                        <img src={MinusIcon} alt='-' />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Box>{source.sourceSubAccountId || 'Unknown'}</Box>
                </Box>

                <Box className={classes.textRight}>
                  {formatBalance((computedReverseBalance(source) ? -1 : 1) * source.sourceTotal)}
                </Box>
                <Box className={clsx([classes.textCenter])}>
                  <Switch
                    inputProps={{ role: 'switch' }}
                    checked={computedReverseBalance(source)}
                    size='small'
                    onChange={(_, val) =>
                      onChangeReverseBalance(source.sourceSubAccountId || undefined, val)
                    }
                    disabled={!editModeEnabled || !isEditableSource(source)}
                  />
                </Box>
                {isEditableSource(source) && (
                  <Box className={classes.textRight}>
                    {formatPercentage(currentSourcePortion(source))}
                  </Box>
                )}
              </>
            ))}

            <Box className={clsx([classes.header, classes.pl30, classes.col1])}>Total</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(sourceTotalSum)}
            </Box>

            <Box className={classes.spacer} />

            <Box className={clsx([classes.header, 'alignItemsEnd'])}>
              Match Balance To
              {editModeEnabled && (
                <Tooltip title='Add account'>
                  <IconButton onClick={onAddAccount} size='small'>
                    <img src={PlusIcon} alt='+' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Source Balance
            </Box>
            <Box className={clsx([classes.header, classes.textCenter, 'alignItemsEnd'])}>
              Adjust
            </Box>
            <Box
              className={clsx([classes.header, classes.col4, classes.textRight, 'alignItemsEnd'])}
            >
              Manually Adjusted Balance
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Portion of Total
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Adjustment
            </Box>

            {detailAdjustmentItems.map((x) => (
              <>
                <Box
                  className={clsx([
                    !editModeEnabled && classes.pl30,
                    classes.col1,
                    'gap4',
                    classes.mh26,
                    (!x.hasCorrectType || !x.isBaseline) && classes.red,
                  ])}
                  key={x.account?.id}
                >
                  {editModeEnabled && (
                    <Tooltip title='Remove account'>
                      <IconButton
                        onClick={() => x.account && onRemoveAccount(x.account.id)}
                        size='small'
                      >
                        <img src={MinusIcon} alt='-' />
                      </IconButton>
                    </Tooltip>
                  )}
                  {x.isBaseline ? (
                    x.hasCorrectType ? (
                      x.account?.accountName || 'Unknown'
                    ) : (
                      <Tooltip
                        title={`This account is not of the correct type (in this case, ${category}). Adjustments defined within a particular account category should only target accounts of the corresponding category.`}
                      >
                        <Box className={clsx([classes.flex, classes.red])}>
                          <img src={AlertIcon} alt='alert' />
                          {x.account?.accountName || 'Unknown'}
                        </Box>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip
                      title={
                        <Box>
                          This account is not included in the baseline adjustment calculation. To
                          remedy this, either:
                          <ul>
                            <li>Add this account to the baseline adjustment calculation, or</li>
                            <li>Remove this account as an adjustment target.</li>
                          </ul>
                        </Box>
                      }
                    >
                      <Box className={clsx([classes.flex, classes.red])}>
                        <img src={AlertIcon} alt='alert' />
                        {x.account?.accountName || 'Unknown'}
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Box
                  className={clsx([
                    classes.textRight,
                    (!x.hasCorrectType || !x.isBaseline) && classes.red,
                  ])}
                >
                  {formatBalance(x.isBaseline ? x.initialBalance : 0)}
                </Box>
                <Box className={clsx([classes.textCenter])}>
                  <Switch
                    inputProps={{ role: 'switch' }}
                    checked={x.adjusted}
                    size='small'
                    disabled={!editModeEnabled}
                    onChange={(_, val) => x.account && onChangeAdjustStatus(val, x.account.id)}
                  />
                </Box>
                {x.adjusted && (
                  <Box
                    className={clsx([
                      classes.col4,
                      classes.textRight,
                      (!x.hasCorrectType || !x.isBaseline) && classes.red,
                    ])}
                  >
                    {formatBalance(x.balance)}
                  </Box>
                )}
                {x.adjusted && (
                  <Box
                    className={clsx([
                      classes.textRight,
                      (!x.hasCorrectType || !x.isBaseline) && classes.red,
                    ])}
                  >
                    {formatPercentage(
                      totalTargetAccountsBalance
                        ? x.balance / totalTargetAccountsBalance
                        : 1 / detailAdjustmentItems.filter((x) => x.adjusted).length,
                    )}
                  </Box>
                )}
                {x.adjusted && (
                  <Box className={clsx([classes.green, classes.textRight])}>
                    {formatBalance(x.adjustment)}
                  </Box>
                )}
              </>
            ))}

            <Box className={clsx([classes.pl30, classes.header, classes.col1])}>Total</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(totalBaselineAccountsInitialBalance)}
            </Box>
            <Box className={clsx([classes.header, classes.col4, classes.textRight])}>
              {formatBalance(totalTargetAccountsBalance)}
            </Box>
            <Box className={clsx([classes.header, classes.textRight])}>{formatPercentage(1)}</Box>
            <Box className={classes.spacer} />
            <Box className={classes.header}>Total Adjustment</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(totalSourceAdjustment)}
            </Box>
            <Box className={clsx([classes.header, classes.col1])}>
              Line Item{currentDetailsArray.length != 1 ? 's' : ''} Portion
            </Box>
            <Box className={classes.textRight}>{formatPercentage(currentSourcesPortion)}</Box>

            <Box className={clsx([classes.header, classes.col1])}>
              Line Item{currentDetailsArray.length != 1 ? 's' : ''} Adjustment
            </Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(currentSourceAdjustment)}
            </Box>
            <Box className={clsx([classes.header, classes.green, classes.col6, classes.textRight])}>
              {formatBalance(detailAdjustmentsTotalAdjustment)}
            </Box>
          </Box>
        </Container>
      </Box>
      <AccountsDialog
        accounts={accounts}
        selectedAccountIds={baselineAccountIds}
        title='Select Baseline Accounts'
        subtitle={details.sourceSubAccountId || ''}
        secondSubtitle={details.sourceSubAccountDescription || ''}
        category={category}
        onClose={onCloseAccountsDialog}
        open={openAccountsDialog}
      />
      <Loader show={showLoader} />
    </>
  );
});
