import { Box, Container, IconButton, Tooltip } from '@mui/material';
import { formatBalance, formatPercentage } from '../../utils';
import { getAdjustmentDefinition, getAdjustments } from '@services/api';
import { useApi, useConfirm, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AccountsDialog } from '../AccountsDialog';
import { Actions } from '@models/enums/Actions';
import { AdjustmentOperation } from '../../common/AdjustmentOperation';
import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import CheckIcon from '@assets/icons/dashboard/check-circle-filled.svg';
import EditIcon from '@assets/icons/dashboard/edit.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAdjustmentDefinitionData } from '@models/interfaces/additional/IUpdateAdjustmentDefinitionData';
import { Loader } from '@components/Loader';
import { Methods } from '@models/enums/Methods';
import MinusIcon from '@assets/icons/dashboard/minus.svg';
import PlusIcon from '@assets/icons/dashboard/plus-square-green.svg';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import XIcon from '@assets/icons/dashboard/x-circle-red.svg';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

const balanceOperations: string[] = [
  AdjustmentOperation.Initial,
  AdjustmentOperation.Manual,
  AdjustmentOperation.Add,
  AdjustmentOperation.Subtract,
  AdjustmentOperation.Match,
  AdjustmentOperation.Fees,
  AdjustmentOperation.Premium,
];

export interface IBalanceAdjustmentDetails {
  adjustmentDefinitionId?: string;
  balancingAccountName: string;
  balancingAccountId: string;
  balanceSheetTotal: number;
  accountsTotal: number;
  adjustment: number;
  adjustmentToSection: number;
  adjustments: IAdjustment[];
}

interface IProps {
  details: IBalanceAdjustmentDetails;
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
  project: IProject;
  types: number[];
  updateAdjustmentDefinition?: (
    url: string,
    data: IUpdateAdjustmentDefinitionData,
    callback?: () => void,
  ) => void;
  deleteAdjustmentDefinition?: (url: string) => void;
}

export const BalanceAdjustmentDetails = ({
  details,
  accounts,
  category,
  project,
  types,
  updateAdjustmentDefinition,
  deleteAdjustmentDefinition,
}: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();

  const [adjustmentDefinition, setAdjustmentDefinition] = useState<IAdjustmentDefinition>();
  const [detailsAdjustments, setDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [balanceAdjustments, setBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [targetAccountIds, setTargetAccountIds] = useState<string[]>([]);
  const [storedDetailsAdjustments, setStoredDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [storedBalanceAdjustments, setStoredBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [openAccountsDialog, setOpenAccountsDialog] = useState(false);

  const { request: getAdjustmentDefinitionRequest, loading: getAdjustmentDefinitionLoading } =
    useApi(getAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAdjustmentDefinition(data);
          setTargetAccountIds(data.targets.map((x) => x.id));
        }
      },
    });

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceAdjustments = data.items.filter((x) =>
            balanceOperations.includes(x.operation),
          );
          setBalanceAdjustments(balanceAdjustments);

          const detailsAdjustments = data.items.filter(
            (x) => x.adjustmentDefinitionId === details.adjustmentDefinitionId,
          );
          setDetailsAdjustments(detailsAdjustments);
        }
      },
    },
  );

  const { request: reloadBalanceAdjustmentsRequest, loading: reloadBalanceAdjustmentsLoading } =
    useApi(getAdjustments, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceAdjustments = data.items.filter((x) =>
            balanceOperations.includes(x.operation as AdjustmentOperation),
          );
          setBalanceAdjustments(balanceAdjustments);
        }
      },
    });

  const getAccountTotalBalance = useCallback(
    (accountId: string, operations: string[], adjustments: IAdjustment[]) => {
      return adjustments
        .filter((x) => x.accountId === accountId && operations.includes(x.operation))
        .reduce((p, c) => p + c.amount, 0);
    },
    [],
  );

  const targetAccountBalances = useMemo(() => {
    return targetAccountIds.map((x) => ({
      accountId: x,
      balance: getAccountTotalBalance(x, balanceOperations, balanceAdjustments),
    }));
  }, [targetAccountIds, balanceAdjustments]);

  const totalTargetAccountsBalance = useMemo(
    () => targetAccountBalances.reduce((prev, acc) => prev + acc.balance, 0),
    [targetAccountBalances],
  );

  const detailAdjustmentItems = useMemo(() => {
    const targetAccountsData = detailsAdjustments.map((x) => {
      const account = accounts.find((a) => a.id === x.accountId);
      const targetAccountBalance = targetAccountBalances.find(
        (tab) => tab.accountId === x.accountId,
      );
      return {
        account,
        balance: targetAccountBalance?.balance || 0,
        adjustment: !editModeEnabled
          ? x.amount
          : (totalTargetAccountsBalance !== 0
              ? (targetAccountBalance?.balance || 0) / totalTargetAccountsBalance
              : 1 / targetAccountBalances.length) * details.adjustment,
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    return targetAccountsData.sort((a, b) =>
      (a.account?.accountName || 'Unknown').localeCompare(b.account?.accountName || 'Unknown'),
    );
  }, [
    detailsAdjustments,
    accounts,
    types,
    details.adjustment,
    targetAccountBalances,
    totalTargetAccountsBalance,
    editModeEnabled,
  ]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter(
        (x) => types.includes(x.accountType.type) && x.summaryCode !== 1 && !x.isBalancing,
      ),
    [accounts],
  );

  const onSave = () => {
    if (!targetAccountIds.length) {
      toast.error('At least one target account should be specified');
      return;
    }
    const [firstAdjustment] = detailsAdjustments;
    if (
      updateAdjustmentDefinition !== undefined &&
      !!firstAdjustment?.links[Actions.updateDefinition] &&
      adjustmentDefinition
    ) {
      updateAdjustmentDefinition(
        firstAdjustment.links[Actions.updateDefinition].href,
        {
          source: {
            type: adjustmentDefinition.source.type,
            id: adjustmentDefinition.source.value.id,
          },
          targets: targetAccountIds,
          operation: adjustmentDefinition.operation,
          baseline: [],
          tierLimits: [],
        },
        () => {
          if (project.links[Actions.getAdjustmentDefinitions]?.href)
            getAdjustmentDefinitionRequest(
              `${project.links[Actions.getAdjustmentDefinitions].href}/${
                details.adjustmentDefinitionId
              }`,
            );
          setEditModeEnabled(false);
        },
      );
    }
  };

  const onDiscard = () => {
    setTargetAccountIds(adjustmentDefinition?.targets.map((x) => x.id) || []);
    setDetailsAdjustments(storedDetailsAdjustments.slice());
    setBalanceAdjustments(storedBalanceAdjustments.slice());
    setEditModeEnabled(false);
  };

  const onEdit = () => {
    setTargetAccountIds(adjustmentDefinition?.targets.map((x) => x.id) || []);
    setStoredDetailsAdjustments(detailsAdjustments.slice());
    setStoredBalanceAdjustments(balanceAdjustments.slice());
    setEditModeEnabled(true);
  };

  const onDelete = async () => {
    const [firstAdjustment] = detailsAdjustments;
    if (
      deleteAdjustmentDefinition !== undefined &&
      !!firstAdjustment?.links[Actions.deleteDefinition]
    ) {
      await confirm({
        title: 'Delete adjustment',
        description: 'Are you sure you wish to delete this adjustment?',
        confirmationText: 'Confirm',
      });

      deleteAdjustmentDefinition(firstAdjustment.links[Actions.deleteDefinition].href);
    }
  };

  const onRemoveAccount = (accountId: string) => {
    setTargetAccountIds((prev) => prev.filter((x) => x !== accountId));
    setDetailsAdjustments((prev) => prev.filter((x) => x.accountId !== accountId));
  };

  const onAddAccount = () => {
    setOpenAccountsDialog(true);
  };

  const onCloseAccountsDialog = (selectedAccountsIds: string[]) => {
    if (project.links[Actions.getAdjustments]?.href) {
      reloadBalanceAdjustmentsRequest(
        project.links[Actions.getAdjustments].href,
        balanceOperations,
        selectedAccountsIds,
      );
    }
    setTargetAccountIds(selectedAccountsIds.slice());
    setDetailsAdjustments((prev) => {
      const filteredAdjustments = prev.filter((x) => selectedAccountsIds.includes(x.accountId));
      const accountsIdsWithoutAdjustment = selectedAccountsIds.filter(
        (x) => prev.findIndex((a) => a.accountId === x) === -1,
      );
      return [
        ...filteredAdjustments,
        ...accountsIdsWithoutAdjustment.map(
          (x) =>
            ({
              accountId: x,
              operation: AdjustmentOperation.Balance,
              id: uuidv4(),
              adjustmentDefinitionId: details.adjustmentDefinitionId,
              reverseBalance: false,
              amount: 0,
              sourceAdjustment: details.adjustment,
              sourceTotal: details.balanceSheetTotal,
              links: {
                [Actions.updateDefinition]: {
                  href: `${project.links[Actions.getAdjustmentDefinitions].href}/${
                    details.adjustmentDefinitionId
                  }`,
                  method: Methods.put,
                } as ILink,
              },
            } as IAdjustment),
        ),
      ];
    });

    setOpenAccountsDialog(false);
  };

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]?.href && details.adjustmentDefinitionId)
      getAdjustmentDefinitionRequest(
        `${project.links[Actions.getAdjustmentDefinitions].href}/${details.adjustmentDefinitionId}`,
      );
  }, [project.links[Actions.getAdjustmentDefinitions]?.href, details.adjustmentDefinitionId]);

  useEffect(() => {
    if (adjustmentDefinition) {
      const usedAccountIds = Array.from(
        new Set([
          ...adjustmentDefinition.targets.map((x) => x.id),
          ...adjustmentDefinition.baseline.map((x) => x.id),
        ]),
      );

      if (project.links[Actions.getAdjustments]?.href) {
        getAdjustmentsRequest(
          project.links[Actions.getAdjustments].href,
          [...balanceOperations, AdjustmentOperation.Balance],
          usedAccountIds,
        );
      }
    }
  }, [
    filteredAccounts,
    adjustmentDefinition,
    balanceOperations,
    project.links[Actions.getAdjustments]?.href,
  ]);

  const detailAdjustmentsTotalAdjustment = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.adjustment, 0),
    [detailAdjustmentItems],
  );

  const detailAdjustmentsTotalAccountsBalance = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.balance, 0),
    [detailAdjustmentItems],
  );

  const showLoader = useLoader(
    getAdjustmentDefinitionLoading,
    getAdjustmentsLoading,
    reloadBalanceAdjustmentsLoading,
  );

  const deleteEnabled = useMemo(() => {
    if (!adjustmentDefinition) return false;
    const [firstAdjustment] = detailsAdjustments;
    return (
      deleteAdjustmentDefinition !== undefined && !!firstAdjustment?.links[Actions.deleteDefinition]
    );
  }, [deleteAdjustmentDefinition, detailsAdjustments, adjustmentDefinition]);

  const updateEnabled = useMemo(() => {
    if (!adjustmentDefinition) return false;
    const [firstAdjustment] = detailsAdjustments;
    return (
      updateAdjustmentDefinition !== undefined && !!firstAdjustment?.links[Actions.updateDefinition]
    );
  }, [updateAdjustmentDefinition, detailsAdjustments, adjustmentDefinition]);

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          <Box className={classes.grid}>
            <Box className={clsx([classes.header, classes.colSpan4, classes.mh26])}>
              <Box>{details.balancingAccountName}</Box>
              {!editModeEnabled && updateEnabled && (
                <Tooltip title='Edit adjustment'>
                  <IconButton onClick={onEdit} size='small'>
                    <img src={EditIcon} alt='edit' />
                  </IconButton>
                </Tooltip>
              )}
              {!editModeEnabled && deleteEnabled && (
                <Tooltip title='Delete adjustment'>
                  <IconButton onClick={onDelete} size='small'>
                    <img src={TrashIcon} alt='trash' />
                  </IconButton>
                </Tooltip>
              )}
              {editModeEnabled && (
                <Tooltip title='Accept changes'>
                  <IconButton onClick={onSave} size='small'>
                    <img src={CheckIcon} alt='check' />
                  </IconButton>
                </Tooltip>
              )}
              {editModeEnabled && (
                <Tooltip title='Discard changes'>
                  <IconButton onClick={onDiscard} size='small'>
                    <img src={XIcon} alt='x' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box className={clsx([classes.header, classes.textRight])}>Section Adjustment</Box>
            <Box className={classes.spacer} />
            <Box className={classes.header}>Balance Sheet Total</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(details.balanceSheetTotal)}
            </Box>
            <Box className={clsx([classes.header, classes.col1])}>Accounts Total</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(details.accountsTotal)}
            </Box>
            <Box className={classes.spacer} />
            <Box className={clsx([classes.header, 'alignItemsEnd'])}>
              Apply Adjustment To
              {editModeEnabled && (
                <Tooltip title='Add account'>
                  <IconButton onClick={onAddAccount} size='small'>
                    <img src={PlusIcon} alt='+' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box
              className={clsx([classes.header, classes.col3, classes.textRight, 'alignItemsEnd'])}
            >
              Balance
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Portion of Total
            </Box>
            <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
              Adjustment
            </Box>

            {detailAdjustmentItems.map((x) => (
              <>
                <Box
                  className={clsx([
                    !editModeEnabled && classes.pl30,
                    classes.col1,
                    'gap4',
                    classes.mh26,
                    !x.hasCorrectType && classes.red,
                  ])}
                  key={x.account?.id}
                >
                  {editModeEnabled && (
                    <Tooltip title='Remove account'>
                      <IconButton
                        onClick={() => x.account && onRemoveAccount(x.account.id)}
                        size='small'
                      >
                        <img src={MinusIcon} alt='-' />
                      </IconButton>
                    </Tooltip>
                  )}
                  {x.hasCorrectType ? (
                    x.account?.accountName || 'Unknown'
                  ) : (
                    <Tooltip
                      title={`This account is not of the correct type (in this case, ${category}). Adjustments defined within a particular account category should only target accounts of the corresponding category.`}
                    >
                      <Box className={clsx([classes.flex, classes.red])}>
                        <img src={AlertIcon} alt='alert' />
                        {x.account?.accountName || 'Unknown'}
                      </Box>
                    </Tooltip>
                  )}
                </Box>

                <Box
                  className={clsx([
                    classes.col3,
                    classes.textRight,
                    !x.hasCorrectType && classes.red,
                  ])}
                >
                  {formatBalance(x.balance)}
                </Box>
                <Box className={clsx([classes.textRight, !x.hasCorrectType && classes.red])}>
                  {formatPercentage(
                    detailAdjustmentsTotalAccountsBalance
                      ? x.balance / detailAdjustmentsTotalAccountsBalance
                      : 1 / detailAdjustmentItems.length,
                  )}
                </Box>

                <Box className={clsx([classes.green, classes.textRight])}>
                  {formatBalance(x.adjustment)}
                </Box>
              </>
            ))}

            <Box className={clsx([classes.pl30, classes.header, classes.col1])}>Total</Box>
            <Box className={clsx([classes.header, classes.col3, classes.textRight])}>
              {formatBalance(detailAdjustmentsTotalAccountsBalance)}
            </Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatPercentage(detailAdjustmentItems.length ? 1 : 0)}
            </Box>
            <Box className={classes.spacer} />
            <Box className={classes.header}>Total Adjustment</Box>
            <Box className={clsx([classes.header, classes.textRight])}>
              {formatBalance(details.adjustment)}
            </Box>
            <Box
              className={clsx([
                classes.header,
                detailAdjustmentsTotalAdjustment ? classes.green : classes.red,
                classes.col5,
                classes.textRight,
              ])}
            >
              {formatBalance(detailAdjustmentsTotalAdjustment)}
            </Box>
          </Box>
        </Container>
      </Box>
      <AccountsDialog
        accounts={accounts}
        selectedAccountIds={targetAccountIds}
        title='Select Target Accounts'
        subtitle={details.balancingAccountName || ''}
        category={category}
        onClose={onCloseAccountsDialog}
        open={openAccountsDialog}
      />
      <Loader show={showLoader} />
    </>
  );
};
