import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {},
  table: {
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-aggregationColumnHeaderLabel': {
      display: 'none',
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-pinnedRows': {
      fontWeight: 600,
      '& div[data-field="code"]': {
        '&::after': {
          content: '"Total"',
          display: 'block',
        },
      },
    },
  },

  flexCell: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: 8,
  },

  limitedHeightTable: {
    maxHeight: 480,
  },

  helperText: {
    position: 'absolute',
    top: 9,
    zIndex: -1,
  },
}));

export default useStyles;
