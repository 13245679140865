import { Autocomplete, Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { CustomAutocomplete, IAutocompleteOption } from '@components/CustomAutocomplete';
import { useCallback, useMemo } from 'react';

import CheckIcon from '@assets/icons/dashboard/check-circle-filled.svg';
import EditIcon from '@assets/icons/dashboard/edit.svg';
import { FileCategoryFieldType } from '@models/enums/FileCategoryFieldType';
import { IFileCategoryField } from '@models/interfaces/entities/IFileCategoryField';
import MinusIcon from '@assets/icons/dashboard/minus.svg';
import PlusIcon from '@assets/icons/dashboard/plus-filled-green.svg';
import TrashIcon from '@assets/icons/dashboard/trash.svg';
import XIcon from '@assets/icons/dashboard/x-circle-red.svg';
import useStyles from './styles';
import { v4 as uuidv4 } from 'uuid';

enum Operation {
  Equals = 'equals',
  Greater = 'greater',
  GreaterOrEqual = 'greaterOrEqual',
  Less = 'less',
  LessOrEqual = 'lessOrEqual',
  Begins = 'begins',
  Ends = 'ends',
  Contains = 'contains',
}

const OperationMap: Readonly<Record<string, string>> = Object.freeze({
  [Operation.Equals]: 'equals',
  [Operation.Greater]: 'is greater than',
  [Operation.GreaterOrEqual]: 'is greater than or equal to',
  [Operation.Less]: 'is less than',
  [Operation.LessOrEqual]: 'is less than or equal to',
  [Operation.Begins]: 'begins with',
  [Operation.Ends]: 'ends with',
  [Operation.Contains]: 'contains',
});

export interface IFormData {
  rules: ISubAccountIdDefinitionRule[];
  parts: ISubAccountIdDefinitionFormatPart[];
}

export interface ISubAccountIdDefinition {
  id: string;
  format: string[];
  rules: {
    fieldId: string;
    operation: string;
    value: string;
  }[];
}

export interface ISubAccountIdDefinitionRule {
  id: string;
  fieldId: IAutocompleteOption | null;
  operation: IAutocompleteOption | null;
  value: string;
}

export interface ISubAccountIdDefinitionFormatPart {
  id: string;
  value: string;
}

export interface IProps {
  fields: Array<IFileCategoryField & { mapped: boolean }>;
  definition: ISubAccountIdDefinition;
  onUpdate: (definition: ISubAccountIdDefinition) => void;
  onDelete: (id: string) => void;
  onEditModeToggled: (id: string, value: boolean) => void;
  editModeEnabled: boolean;
  isDefault: boolean;
  removeOnDiscard: boolean;
}

export const DefinitionCard = ({
  fields,
  definition,
  isDefault,
  onUpdate,
  onEditModeToggled,
  onDelete,
  editModeEnabled,
  removeOnDiscard,
}: IProps) => {
  const { classes } = useStyles();

  const formId = useMemo(() => `definition-${definition.id}-form`, [definition.id]);

  const extendedFieldOptions = useMemo<IAutocompleteOption[]>(() => {
    return fields
      .filter((x) => x.type !== FileCategoryFieldType.date)
      .map((x) => ({
        value: x.id,
        title: x.displayName || x.name,
      }));
  }, [fields]);
  const fieldOptions = useMemo(() => fields.map((x) => x.displayName || x.name), [fields]);
  const mappedFieldOptions = useMemo(
    () => fields.filter((x) => x.mapped).map((x) => x.displayName || x.name),
    [fields],
  );

  const numericOperationOptions = useMemo<IAutocompleteOption[]>(() => {
    return [
      Operation.Equals,
      Operation.Greater,
      Operation.GreaterOrEqual,
      Operation.Less,
      Operation.LessOrEqual,
    ].map((x) => ({
      value: x,
      title: OperationMap[x],
    }));
  }, [fields]);
  const stringOperationOptions = useMemo<IAutocompleteOption[]>(() => {
    return [Operation.Begins, Operation.Contains, Operation.Ends, Operation.Equals].map((x) => ({
      value: x,
      title: OperationMap[x],
    }));
  }, [fields]);

  const formDefaultData = useMemo(
    () => ({
      rules: definition.rules.map((x) => ({
        ...x,
        id: uuidv4(),
        fieldId: extendedFieldOptions.find((o) => o.value === x.fieldId) || null,
        operation:
          stringOperationOptions.find((o) => o.value === x.operation) ||
          numericOperationOptions.find((o) => o.value === x.operation) ||
          null,
      })),
      parts: definition.format.map((x) => ({ value: x, id: uuidv4() })),
    }),
    [definition],
  );

  const form = useForm<IFormData>({
    defaultValues: formDefaultData,
  });

  const {
    fields: rules,
    append: appendRule,
    remove: removeRule,
  } = useFieldArray({ name: 'rules', control: form.control });

  const {
    fields: parts,
    append: appendPart,
    remove: removePart,
  } = useFieldArray({ name: 'parts', control: form.control });

  const watchedRules = form.watch('rules');

  const onFieldIdChanged = useCallback(
    (index: number) => {
      form.setValue(`rules.${index}.operation`, null);
      form.setValue(`rules.${index}.value`, '');
    },
    [form],
  );

  const onAddRule = () => {
    appendRule({
      id: uuidv4(),
      fieldId: null,
      operation: null,
      value: '',
    });
  };

  const onAddPart = () => {
    appendPart({
      id: uuidv4(),
      value: '',
    });
  };

  const onEdit = () => {
    onEditModeToggled(definition.id, true);
    form.reset(formDefaultData);
  };

  const onDiscard = () => {
    onEditModeToggled(definition.id, false);
    form.reset(formDefaultData);
  };

  const onSave = () => {
    const data = form.getValues();
    onUpdate({
      id: definition.id,
      format: data.parts.map((x) => x.value),
      rules: data.rules.map((x) => ({
        fieldId: x.fieldId?.value || '',
        operation: x.operation?.value || '',
        value: x.value,
      })),
    });
    onEditModeToggled(definition.id, false);
  };

  const getOperationsList = useCallback(
    (fieldId?: string) => {
      if (!fieldId) return stringOperationOptions;
      const field = fields.find((x) => x.id === fieldId);
      if (
        field?.type === FileCategoryFieldType.decimal ||
        field?.type === FileCategoryFieldType.integer
      ) {
        return numericOperationOptions;
      }
      return stringOperationOptions;
    },
    [fields, stringOperationOptions, numericOperationOptions],
  );

  const getValueType = useCallback(
    (fieldId?: string) => {
      if (!fieldId) return 'text';
      const field = fields.find((x) => x.id === fieldId);
      if (
        field?.type === FileCategoryFieldType.decimal ||
        field?.type === FileCategoryFieldType.integer
      ) {
        return 'number';
      }
      return 'text';
    },
    [fields],
  );

  const getFieldLabel = useCallback(
    (fieldId?: string) => {
      if (!fieldId) return 'Unknown';
      const field = fields.find((x) => x.id === fieldId);

      return field?.displayName || field?.name || 'Unknown';
    },
    [fields],
  );

  const isFieldMapped = useCallback(
    (fieldId?: string) => {
      if (!fieldId) return false;
      const field = fields.find((x) => x.id === fieldId);

      return field?.mapped || false;
    },
    [fields],
  );

  const getOperationLabel = useCallback(
    (operation?: string) => (operation ? OperationMap[operation] || 'Unknown' : 'Unknown'),
    [fields, stringOperationOptions, numericOperationOptions],
  );

  const isFieldOption = useCallback(
    (value: string) => {
      return fieldOptions.some((x) => x === value);
    },
    [fieldOptions],
  );

  const isMappedFieldOption = useCallback(
    (value: string) => {
      return mappedFieldOptions.some((x) => x === value);
    },
    [mappedFieldOptions],
  );

  return (
    <form
      id={formId}
      onSubmit={form.handleSubmit(() => {
        onSave();
      })}
      noValidate
    >
      <Box className={classes.root}>
        <Box className={classes.controls}>
          {editModeEnabled && (
            <Tooltip title='Accept changes'>
              <IconButton size='small' type='submit' form={formId}>
                <img src={CheckIcon} alt='check' />
              </IconButton>
            </Tooltip>
          )}
          {editModeEnabled && !removeOnDiscard && (
            <Tooltip title='Discard changes'>
              <IconButton onClick={onDiscard} size='small'>
                <img src={XIcon} alt='x' />
              </IconButton>
            </Tooltip>
          )}
          {!editModeEnabled && (
            <Tooltip title='Edit definition'>
              <IconButton onClick={onEdit} size='small'>
                <img src={EditIcon} alt='edit' />
              </IconButton>
            </Tooltip>
          )}
          {!isDefault && (!editModeEnabled || removeOnDiscard) && (
            <Tooltip title='Delete definition'>
              <IconButton onClick={() => onDelete(definition.id)} size='small'>
                <img src={TrashIcon} alt='trash' />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        {isDefault ? (
          <Typography>Default</Typography>
        ) : editModeEnabled ? (
          <>
            {rules.map((x, index) => (
              <Box key={x.id} className={classes.ruleContainer}>
                <>
                  <Box className={classes.rulePrefix}>
                    {index === 0 ? (
                      <>
                        <Box />
                        <Typography>When</Typography>
                      </>
                    ) : (
                      <>
                        <Tooltip title='Remove condition'>
                          <IconButton onClick={() => removeRule(index)} size='small'>
                            <img src={MinusIcon} alt='-' />
                          </IconButton>
                        </Tooltip>
                        <Typography>and</Typography>
                      </>
                    )}
                  </Box>
                  <Controller
                    name={`rules.${index}.fieldId`}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the field',
                      },
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <CustomAutocomplete
                        options={extendedFieldOptions}
                        value={value}
                        onChange={(value) => {
                          onFieldIdChanged(index);
                          onChange(value);
                        }}
                        error={!!error}
                        helperText={error?.message}
                        variant='outlined'
                        size='small'
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name={`rules.${index}.operation`}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select the operation',
                      },
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <CustomAutocomplete
                        options={getOperationsList(watchedRules[index].fieldId?.value)}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        fullWidth
                        variant='outlined'
                        size='small'
                      />
                    )}
                  />
                  <Controller
                    name={`rules.${index}.value`}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please enter the value',
                      },
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        fullWidth
                        label={null}
                        variant='outlined'
                        size='small'
                        error={!!error}
                        helperText={error?.message}
                        onChange={onChange}
                        value={value}
                        autoComplete='off'
                        type={getValueType(watchedRules[index].fieldId?.value)}
                      />
                    )}
                  />
                </>
              </Box>
            ))}

            <Tooltip title='Add condition'>
              <IconButton className={classes.mtn24} onClick={onAddRule} size='small'>
                <img src={PlusIcon} alt='+' />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            {definition.rules.map((x, index) => (
              <Box key={index} className={classes.readonlyRuleContainer}>
                {index === 0 ? <Typography>When</Typography> : <Typography>and</Typography>}
                {isFieldMapped(x.fieldId) ? (
                  <Typography className={classes.textHighlightedBlue}>
                    {getFieldLabel(x.fieldId)}
                  </Typography>
                ) : (
                  <Tooltip title='Used field is not mapped'>
                    <Typography className={classes.textHighlightedRed}>
                      {getFieldLabel(x.fieldId)}
                    </Typography>
                  </Tooltip>
                )}

                <Typography>{getOperationLabel(x.operation)}</Typography>
                <Typography>{x.value}</Typography>
              </Box>
            ))}
          </>
        )}

        <Box
          className={editModeEnabled ? classes.formatContainer : classes.readonlyFormatContainer}
        >
          <Typography>then Type Code equals</Typography>
          {editModeEnabled ? (
            <>
              {parts.map((x, index) => (
                <Controller
                  key={x.id}
                  name={`parts.${index}.value`}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter the value',
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                      className={classes.autocomplete}
                      freeSolo
                      options={fieldOptions}
                      value={value}
                      onInputChange={(e, v) => {
                        onChange({ target: { value: v } });
                      }}
                      filterOptions={(options, params) => {
                        const { inputValue } = params;
                        const filtered = options.filter((option) =>
                          option.toLowerCase().includes(inputValue.toLowerCase()),
                        );

                        if (
                          inputValue !== '' &&
                          !options.some(
                            (option) => option.toLowerCase() === inputValue.toLowerCase(),
                          )
                        ) {
                          filtered.push(`Custom text "${inputValue}"`);
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          size='small'
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              ))}
              {parts.length > 1 && (
                <Tooltip title='Remove condition'>
                  <IconButton onClick={() => removePart(parts.length - 1)} size='small'>
                    <img src={MinusIcon} alt='-' />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title='Add part'>
                <IconButton onClick={onAddPart} size='small'>
                  <img src={PlusIcon} alt='+' />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Typography>
                {definition.format.map((x, index) =>
                  isMappedFieldOption(x) ? (
                    <span className={classes.textHighlightedBlue} key={index}>
                      {x}
                    </span>
                  ) : isFieldOption(x) ? (
                    <Tooltip key={index} title='Used field is not mapped'>
                      <span className={classes.textHighlightedRed}>{x}</span>
                    </Tooltip>
                  ) : (
                    <span key={index}>{x}</span>
                  ),
                )}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
};
