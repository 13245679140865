import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {},
  table: {
    backgroundColor: t.palette.custom.white[100],

    '& .MuiDataGrid-virtualScroller': {
      minHeight: 36,
    },

    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },

    '& .MuiDataGrid-aggregationColumnHeaderLabel': {
      display: 'none',
    },

    '& .MuiDataGrid-pinnedRows': {
      fontWeight: 600,
      '& div[data-field="__detail_panel_toggle__"] > button': {
        display: 'none',
      },
      '& div[data-field="adjustment"]': {
        '&::after': {
          content: '"Total"',
          display: 'block',
        },
      },
    },
  },
  red: {
    color: t.palette.custom.error[100],
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

export default useStyles;
